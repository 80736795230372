$font-sans-serif: "proxima-nova", sans-serif;
$font-serif: "adobe-caslon-pro", serif;
$font-size:16px;
$font-size-sml:14px;

h1, h2, h3, h4, h5, h6 {
	margin: 0 0 0.75rem 0;
  line-height:1.1em;
}
h1 {
	@include font-size(40px);

	@include media($ptab) {
		@include font-size(60px);
	}
}
h2 {
	@include font-size(36px);
	margin-bottom:2rem;

	@include media($desk) {
		@include font-size(42px);
	}
	@include media($wideDesk) {
		@include font-size(48px);
	}
}
h3 {
	@include font-size(30px);
}
h4 {
  @include font-size(20px);
	text-transform:uppercase;
}
