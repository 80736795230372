//Lightbox

.lightbox-wrapper {
    display: none;
}

.lightbox-active {
    overflow: hidden;
}

.lightbox-modal {
    opacity: 0;
    pointer-events: none;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 99;
    display: flex;
    justify-content: center;
    padding: 100px 24px;
    transition: opacity .5s ease-in-out;
    overflow: scroll;

    img {
        box-shadow: 0 0 10px rgba(0,0,0,0.2);
    }

    &:before {
        content: '';
        background: black;
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        opacity: 0.3;
        z-index: 1;
    }

    &--open {
        opacity: 1;
        pointer-events: auto;
    }

    &__close {
        position: absolute;
        top: -48px;
        right: -5px;
        border: 0;
        fill: white;

        svg {
            fill: inherit;
            width: 30px;
            height: 30px;
        }

        &:hover {
            fill: $brand-copper;
        }
    }

    &__content-wrapper {
        position: relative;
        z-index: 1;
        background: $color-grey-50;
        width: 1024px;
        max-width: 100%;
        padding:  24px;
        margin: auto;
        box-shadow: 0 0 10px 3px rgba(0,0,0,0.3);
    }

    .modal-content__down {
        position: fixed;
        bottom: 30px;
        width: 50px;
        left: 0;
        right: 0;
        margin: auto;
    }

    .wp-caption {
        position: relative;
        max-width: 100%;

        .wp-caption-text {
            width: 100%;
            margin: auto;
            padding: 32px;
            text-align: left;
            font-family:$font-sans-serif;
            font-style: normal;
            background: $brand-copper;
            color: white;
            box-shadow: 0 0 10px rgba(0,0,0,0.2);

            @include media($mobileOnly) {
                padding: 16px 8px;
            }

        }
    }
}

