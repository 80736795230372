.page__home {
	margin: 0;

	&.show-copy {
		.more-copy {
			display: flex;
		}

		.intro {
			// margin:3rem 0 0 0;
			//
			// @include media($tab) {
			//   margin:0;
			// }

			.excerpt {
				button {
					display: none;
				}
			}
		}
	}

	&__content {
		width: 100%;

		@include media($tab) {
			width: 100vw;
		}

		p {
			font-size: 0.9rem;
			line-height: 1.5em;
			color: $color-grey-600;
		}

		blockquote {
			border-left: solid 1px $brand-copper;
			padding: 0;
			margin: 0 0 2rem 0;

			p {
				padding: 0 0 0 2rem;
				margin: 0;
				font-size: 0.8rem;
			}
		}

		.intro {
			margin: 3rem 0 25px 0;
			padding: 0 1rem;
			display: flex;
			align-items: flex-end;

			@include media($tab) {
				margin: 0;
				padding: 0 2rem 1rem 2rem;
				height: 100vh;
			}

			figure {
				margin: 0;
			}

			.wrap {
				display: flex;
				flex-wrap: wrap;
				align-items: flex-end;
			}

			.logo {
				flex: 0 0 100%;
				margin: 0 0 2rem 0;

				img {
					width: 150px;
					height: auto;
					display: block;
				}
			}

			.excerpt {
				flex: 0 0 100%;

				@include media($tab) {
					max-width: 75%;
				}

				.mobile-image {
					width: 100%;
					height: auto;
					display: block;
					margin: 0 0 1rem 0;

					@include media($tab) {
						display: none;
					}
				}

				.blockquote-slider {
					position: relative;
					display: none;

					@include media($tab) {
						display: block;
					}

					blockquote {
						position: absolute;
						top: 0;
						left: 0;
						opacity: 0;
						transition: opacity .5s ease;

						&.show {
							opacity: 1;
							position: relative;
						}
					}
				}

				p {
					margin: 0;
				}

				button {
					display: none;
					border: none;
					line-height: 1em;
					padding: 0;
					background: transparent;
					color: $brand-red;
					outline: none;
					user-select: none;

					@include media($tab) {
						display: inline-block;
					}

					span {
						&:first-child {
							display: block;
						}

						&:last-child {
							display: none;
						}
					}
				}
			}
		}
	}

	&__slider {
		position: relative;
		top: 0;
		right: 0;
		width: 100vw;
		background: white;

		@include media($laptop) {
			height: 95vh;
		}

		.slides {
			position: relative;
			width: 100%;
			height: 100%;
		}

		.slide {

			display: none;
			flex-wrap: wrap;
			opacity: 0;
			z-index: 0;
			transition: opacity 1s ease;
			background: white;
			overflow: visible;

			@include media($laptop) {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				overflow: hidden;
			}

			&.active {
				display: flex;
				z-index: 1;
				opacity: 1;

				@include media($laptop) {
					.title {
						transform: translate3d(0, 150px, 0);
						opacity: 1;
					}

					.description {
						transform: translate3d(0, 50px, 0);
						opacity: 1;
					}

					.description {
						transition-delay: 1s;
					}

					.title {
						transition-delay: .5s;
					}
				}


			}

			.slide-content {
				width: 100%;
				order: 2;
				margin-top: 100px;

				@include media($laptop) {
					width: 30%;
					margin-right: 10%;
					margin-top: unset;
					order: 1;
				}
			}

			.bg-image {
				// opacity: 0.7;
				margin: 0 1rem;
				margin-top: 42px;
				width: 100%;
				background-position: center;
				height: 400px;
				background-size: cover;
				position: unset;
				order: 1;
				z-index: auto;
				background-size: contain;
				background-size: auto 100%;
				background-position: center;

				@include media($ptab) {
					background-size: 100% auto;
					height: 550px;
				}

				@include media($laptop) {
					background-size: auto 100%;
					background-position: right;
					margin: unset;
					width: 60%;
					height: auto;
					font-size: 45px;
					line-height: 47px;
					order: 2;
				}
			}

			.description,
			.title {
				flex: 0 0 100%;
				margin: 0;
				color: black;


				@include media($laptop) {
					transition: all 1s ease 0s;
					transform: translate3d(0, 250px, 0);
					opacity: 0;
				}
			}

			.description {
				font-size: 0.8rem;
				line-height: 1.5em;
				margin: 0 1rem;
				font-weight: 600;
				// max-width: 250px;

				@include media($laptop) {
					margin: calc(67px + 2rem) 0 0 2rem;
					transition-delay: 0s;
				}
			}

			.title {
				align-self: flex-end;
				font-size: 36px;
				line-height: 38px;
				font-weight: bold;
				margin: 0 1rem;
				padding-bottom: 1rem;


				@include media($laptop) {
					font-size: 45px;
					line-height: 47px;
					margin: 0 0 8rem 2rem;
					transition-delay: .25s;
				}
			}
		}

		.additional-slide-content {
			position: absolute;
			bottom: unset;
			left: 0;
			z-index: 2;
			width: 100%;
			bottom: 4rem;

			@include media($laptop) {
				width: 30%;
				top: unset;
				left: 1rem;
				bottom: 9rem;
			}

			p {
				font-weight: normal;
				font-size: 16px;
				line-height: 23px;
				margin: 0 1rem;

				&:nth-of-type(2) {
					font-weight: bold;
					margin-top: 1rem;
					margin-bottom: 1rem;
				}
			}
		}

		.pages {
			position: absolute;
			top: 460px;
			bottom: unset;
			left: 1rem;
			z-index: 2;

			@include media($ptab) {
				top: 610px;
			}

			@include media($laptop) {
				top: unset;
				// left: 2rem;
				bottom: 3rem;
				left: unset;
				right: 2rem;
			}

			button {
				border-radius: 100%;
				width: 50px;
				height: 50px;
				border: solid 1px black;
				background: transparent;
				transition: all .5s ease;
				color: black;
				margin: 0 0.5rem 0 0;
				outline: 0;
				user-select: none;

				@include media($ptab) {
					background: white;
					color: black;
				}

				&:hover,
				&.active {
					background: white;
					color: black;

					@include media($ptab) {
						background: black;
						color: white;
					}


				}
			}
		}
	}


	&__additional-content {
		margin-top: 4rem;

		@include media($laptop) {
			margin-top: unset;
		}

		.additional-content {
			padding: 4rem 0;
			margin: 0 auto;
			display: flex;
			flex-direction: column;
			justify-content: center;
			grid-gap: 1.5rem;

			@include media($tab) {
				flex-direction: row;
				grid-gap: 4rem;
			}

			.left-content {
				width: auto;

				@include media($tab) {
					width: 400px;
				}

				p {
					font-weight: bold;
					margin-bottom: 1rem;

					&:last-of-type {
						margin-bottom: 0;
					}
				}
			}

			.right-content {
				width: auto;

				@include media($tab) {
					width: 400px;
				}

				p {
					margin-bottom: 1rem;

					&:first-of-type {
						font-weight: bold;

						@include media($tab) {
							flex-direction: row;
							grid-gap: 1rem;
						}
					}

					&:last-of-type {
						margin-bottom: 0;
					}
				}
			}
		}
	}


	#cover {
		.content-wrap {
			max-width: 1100px;

			.page-title {
				font-size: 38px;
				line-height: 42px;

				@include media($tab) {
					font-size: 45px;
					line-height: 47px;
				}
			}

			.page-cover-content {
				font-weight: normal;

				.question {
					font-weight: bold;
					font-size: 24px;
					line-height: 30px;

					@include media($tab) {
						font-size: 36px;
						line-height: 52px;
					}
				}

				.answer {
					font-size: 20px;
					line-height: 26px;
					margin-top: 2rem;

					@include media($tab) {
						font-size: 28px;
						line-height: 43px;
						margin-top: 2.75rem;
					}

					strong {
						color: #AE7252;
					}
				}
			}
		}
	}

	&__accordion {
		margin-top: 5rem;
		margin-bottom: 5rem;

		@include media($tab) {
			margin-top: 8rem;
			margin-bottom: 8rem;
		}

		.content-wrap {
			max-width: 1100px;
		}

		.home-accordion {
			cursor: pointer;
			width: 100%;
			border: none;
			text-align: left;
			outline: none;
			font-size: 15px;
			transition: 0.4s;
			font-weight: bold;
			font-size: 20px;
			line-height: 26px;
			letter-spacing: -0.01em;
			margin-bottom: 1.5rem;
			display: flex;

			@include media($tab) {
				font-size: 24px;
				line-height: 40px;
			}

			&:before {
				content: '\002B';
				font-weight: bold;
				margin-right: 10px;
			}

			&.active:before {
				content: "\2212";
			}
		}

		.home-accordion.active+.panel {
			margin-bottom: 3rem;
			margin-top: 1.5rem;
		}

		.panel {
			padding: 0 18px;
			background-color: white;
			max-height: 0;
			overflow: hidden;
			transition: max-height 0.2s ease-out;
			margin-top: 2.5rem;

			p,
			ul {
				font-size: 16px;
				line-height: 22px;
				margin-top: 0;

				@include media($tab) {
					font-size: 18px;
					line-height: 28px;
				}

				strong {
					color: #AE7252;
				}
			}

			p {
				img {
					margin: 0 auto;
					margin-top: 1.5rem;
					width: 100%;
					max-width: 850px;
				}
			}

			table {

				th,
				td {
					padding: 0 0.5rem;
					font-size: 15px;
					border: 1px solid;

					@include media($tab) {
						padding: 0;
						padding-right: 1.5rem;
						font-size: unset;
						border: unset;
					}


				}
			}
		}

		.read-full-article {
			font-size: 16px;
			line-height: 22px;
			margin-top: 1rem;

			@include media($tab) {
				font-size: 18px;
				line-height: 28px;
			}
		}
	}



	&__content5050 {
		.content5050 {
			display: flex;
			flex-direction: column;

			@include media($tab) {
				flex-direction: row;
			}

			.text {
				font-weight: bold;
				font-size: 20px;
				line-height: 28px;
				text-align: center;
				letter-spacing: -0.01em;

				@include media($tab) {
					font-size: 32px;
					line-height: 40px;
				}

				.inner-text {
					max-width: 95%;
				}

				.btn5050 {
					box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.19);
					border-radius: 28px;
					font-weight: bold;
					font-size: 14px;
					line-height: 20px;
					padding: 1rem 2rem;
				}

				&.left {
					background-color: #FAFAFA;

					.btn5050 {
						background: #000000;
						color: #FFFFFF;
					}
				}

				&.right {
					background-color: #AE7252;
					color: white;

					.btn5050 {
						background: #FFFFFF;
						color: #000000;
					}
				}
			}
		}
	}




	.more-copy {
		padding: 0 1rem 1rem 1rem;
		flex-wrap: wrap;

		@include media($tab) {
			display: none;
			padding: 0 2rem 1rem 2rem;
		}

		p {
			flex: 0 0 100%;

			@include media($tab) {
				max-width: 75%;
			}
		}

		blockquote {
			@include media($tab) {
				display: none;
			}
		}

		.btn__next {
			font-weight: bold;
			border-bottom: solid 1px black;
			display: inline-block;
		}
	}
}