.timeline {
	display: flex;
	position: relative;
	padding: 170px 0;

	&__item {
		position: relative;
		width: 10%;
		height: 877px;

		&:nth-child(even) {

			.timeline-date {
				top: -35px;
				bottom: auto;
				transform: translate(0, -40px);
			}

			.timeline-block {
				top: 50%;
				bottom: auto;
				flex-direction: column;
				transform: translate(0, 10px);

				&--show {
					transform: translate(0,0);

					.timeline-date {
						transform: translate(0,0);
					}
				}

				@include media($deskAndLess) {
					flex-direction: column;
				}
			}

		}

		@include media($deskAndLess) {
			position: static;
			width: 320px;
			height: auto;
			padding: 32px 8px;
		}
	}

	.timeline-block {
		display: flex;
		flex-direction: column-reverse;
		position: absolute;
		width: 100%;
		bottom: 50%;
		opacity: 0;
		transition: all 1s ease-in-out;
		transform: translate(0, -10px);

		&--show {
			opacity: 1;
			transform: translate(0,0);

			.timeline-date {
				transform: translate(0,0);
			}
		}

		&__image {
			border-radius: 50%;
			border: 2px solid $brand-copper;
			overflow: hidden;
			width: 100%;
			height: auto;
			margin: 0 auto;
		}

		&__text {
			margin: 24px auto;
			font-size:0.8rem;
			line-height:1.5em;
		}

		.copper {
			color: $brand-copper;
		}

		&:before {
			content: '';
			width: 2px;
			height: 70%;
			min-height: 80px;
			margin: auto;
			display: block;
			border: 1px dashed #c4c4c4;

			@include media($deskAndLess) {
				display: none;
			}
		}


		@include media($deskAndLess) {
			position: static;
			flex-direction: column;
			width: 100%;
			padding-top: 8px;
		}
	}

	.timeline-date {
		position: absolute;
		text-align: center;
		width: 100%;
		margin: auto;
		bottom: -35px;
		font-size: 1.5rem;
		color: $brand-copper;
		font-weight: bold;
		transition: all 1s ease-in-out;
		transform: translate(0, 40px);

		@include media($deskAndLess) {
			top: -35px;
			bottom: auto;
			transform: translate(0, -40px);
		}
	}

	&:after {
		content: '';
		width: 0;
		height: 2px;
		background: #c4c4c4;
		position: absolute;
		top: 0;
		bottom: 0;
		margin: auto;
		transition: width 1s ease-in-out;

		@include media($deskAndLess) {
			display: none;
		}
	}

	&--ready {

		&:after {
			content: '';
			width: 100%;
		}
	}

	@include media($deskAndLess) {
		flex-wrap: wrap;
		justify-content: space-evenly;
		padding: 32px 0;
	}
}
